<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <c-table
          ref="table"
          title="권한"
          tableId="table"
          :columnSetting="false"
          :usePaging="false"
          :isFullScreen="false"
          :columns="grid.columns"
          :data="grid.data"
          :isExcelDown="false"
          :filtering="false"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getGrpList" />
            </q-btn-group>
          </template>
          <!-- <template slot="table-header-append">
            <c-select
              codeGroupCd="DEVICE_CD"
              codeAttrVal1="Y"
              type="none"
              itemText="codeName"
              itemValue="code"
              name="deviceTypeCd"
              label=""
              v-model="searchParam.deviceTypeCd"
              @input="getGrpList"
            ></c-select>
          </template> -->
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <q-form ref="editForm">
          <c-card title="LBLDETAIL" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="addGrp" />
                <c-btn
                  v-if="editable && deleteable && data.defaultFlag=='N'"
                  :url="deleteUrl"
                  :isSubmit="true"
                  :param="data"
                  mappingType="DELETE"
                  label="LBLREMOVE"
                  icon="remove"
                  @beforeAction="deleteGrp"
                  @btnCallback="deleteCallback" />
                <c-btn
                  v-if="editable && saveable && data.defaultFlag=='N'"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="saveType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveGrp"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-6">
                <c-text
                  :editable="editable && dataeditable"
                  :required="true"
                  label="권한명"
                  name="sysAuthGrpName"
                  v-model="data.sysAuthGrpName">
                </c-text>
              </div>
              <div class="col-3">
                <c-select
                  :editable="false"
                  codeGroupCd="DEVICE_CD"
                  codeAttrVal1="Y"
                  type="edit"
                  :required="true"
                  itemText="codeName"
                  itemValue="code"
                  name="deviceTypeCd"
                  label="디바이스구분"
                  v-model="data.deviceTypeCd"
                ></c-select>
              </div>
              <div class="col-3">
                <c-text
                  :disabled="true"
                  label="권한 코드"
                  name="sysAuthGrpId"
                  v-model="data.sysAuthGrpId">
                </c-text>
              </div>
              <div class="col-12">
                <c-textarea
                  :editable="editable && dataeditable"
                  label="설명"
                  name="remark"
                  :rows="3"
                  v-model="data.remark">
                </c-textarea>
              </div>
              <div class="col-6">
                <c-select
                  :editable="editable && dataeditable"
                  :comboItems="dashboardItems"
                  type="edit"
                  itemText="menuNm"
                  itemValue="sysMenuId"
                  name="dashboardId"
                  label="기본 대시보드(메인)"
                  v-model="data.dashboardId"
                ></c-select>
              </div>
              <div class="col-6">
                <c-text
                  :editable="editable && dataeditable"
                  type="number"
                  label="대시보드 우선순위"
                  name="dashboardOrder"
                  v-model="data.dashboardOrder">
                </c-text>
              </div>
              <div class="col-6">
                <c-checkbox
                  :editable="editable && dataeditable"
                  :isFlag="true"
                  label="LBLUSEFLAG"
                  name="useFlag"
                  v-model="data.useFlag"
                />
              </div>
              <!-- <div class="col-6">
                <c-radio
                  :editable="editable"
                  :comboItems="defaultFlagItems"
                  label="사용자 기본권한여부"
                  name="defaultFlag"
                  v-model="data.defaultFlag">
                </c-radio>
              </div> -->
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'auth-group',
  data() {
    return {
      searchParam: {
        useFlag: '',
        deviceTypeCd: 'P',
      },
      dashboardItems: [],
      grid: {
        columns: [
          {
            name: 'sysAuthGrpName',
            field: 'sysAuthGrpName',
            label: '권한명',
            align: 'left',
            sortable: true,
          },
          {
            name: 'dashboardName',
            field: 'dashboardName',
            label: '기본대시보드',
            align: 'center',
            style: 'width: 150px',
            sortable: true,
          },
          {
            name: 'dashboardOrder',
            field: 'dashboardOrder',
            label: '대시보드우선순위',
            style: 'width: 120px',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      data: {
        sysAuthGrpId: '',  // 권한코드 SEQ
        sysAuthGrpName: '',  // 권한명
        remark: '',  // 권한 설명
        useFlag: 'Y',  // 사용여부
        defaultFlag: 'N',  // 사용자기본권한여부
        dashboardId: null,
        dashboardOrder: null,
        deviceTypeCd: 'P',
      },
      defaultFlagItems: [
        { code: 'Y', codeName: '기본권한적용' },
        { code: 'N', codeName: '미적용' },
      ],
      listUrl: '',
      detailUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      saveable: false,
      deleteable: false,
      editable: true,
      dataeditable: false,
      updateMode: false,
      isSave: false,
      saveUrl: transactionConfig.sys.auth.grp.insert.url,
      saveType: 'POST',
      searchUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.dataeditable = false;
      // api scope
      this.listUrl = selectConfig.sys.auth.grp.list.url;
      this.detailUrl = selectConfig.sys.auth.grp.get.url;
      this.insertUrl = transactionConfig.sys.auth.grp.insert.url;
      this.updateUrl = transactionConfig.sys.auth.grp.update.url;
      this.deleteUrl = transactionConfig.sys.auth.grp.delete.url;
      
      this.$http.url = selectConfig.sys.menu.dashboard.url;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.dashboardItems = _result.data;
      },);
      this.getList();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getGrpList() {
      this.getList();
      this.rowRemoveSelect();
      this.reset();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        if (this.$store.getters.user.userId === 'U000000001') {
          this.grid.data = _result.data;
        } else {
          this.grid.data = this.$_.filter(_result.data, item => {return item['sysAuthGrpId'] !== 'SAGI000000'});
        }
      },);
      this.saveable = false;
      this.deleteable = false;
    },
    rowClick(row) {
      // 상세조회
      this.saveable = true;
      this.deleteable = true;
      this.updateMode = true;
      this.$http.url = this.$format(this.detailUrl, row.sysAuthGrpId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.data = _result.data;
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
        this.dataeditable = true;
      },
      () => {
      });
    },
    addGrp() {
      this.saveable = true;
      this.deleteable = false;
      this.saveUrl = this.insertUrl;
      this.saveType = 'POST';
      this.updateMode = false;
      this.dataeditable = true;
      this.data = {
        sysAuthGrpId: '',  // 권한코드 SEQ
        sysAuthGrpName: '',  // 권한명
        remark: '',  // 권한 설명
        useFlag: 'Y',  // 사용여부
        defaultFlag: 'N',  // 사용자기본권한여부
        dashboardId: null,
        dashboardOrder: null,
        deviceTypeCd: this.searchParam.deviceTypeCd,
      };
      this.rowRemoveSelect();
    },
    reset() {
      this.rowRemoveSelect();
      this.saveable = false;
      this.deleteable = false;
      this.updateMode = false;
      this.dataeditable = false;
      this.data = {
        sysAuthGrpId: '',  // 권한코드 SEQ
        sysAuthGrpName: '',  // 권한명
        remark: '',  // 권한 설명
        useFlag: 'Y',  // 사용여부
        defaultFlag: 'N',  // 사용자기본권한여부
        dashboardId: null,
        dashboardOrder: null,
        deviceTypeCd: this.searchParam.deviceTypeCd,
      };
    },
    saveGrp() {
      this.$refs['editForm'].validate().then(_result => {
        // 중복체크
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    deleteGrp() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.data.sysAuthGrpId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.isSave = !this.isSave;
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.saveType == 'PUT') {
        this.getList();
        this.rowClick({ sysAuthGrpId: result.data.sysAuthGrpId })
      } else {
        this.getGrpList();
      }
    },
    deleteCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getGrpList();
    },
  }
};
</script>
